import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Box,
  Collapse,
  ButtonGroup,
  Button,
} from "@mui/material";
import { createChart } from "lightweight-charts";
import algoLogo from "../assets/images/algo.png";
import usdIcon from "../assets/images/usd.png";
import Menu from "@mui/icons-material/Menu";

const buttonSx = {
  "&:hover": {
    backgroundColor: "#48466D",
    color: "#fff",
  },
  height: "20px",
  backgroundColor: "#0081c6",
  color: "#fff",


};
const activeButtonSx = {
  backgroundColor: "#48466D",
  color: "#fff",
  height: "20px",


};

class ChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.selectorRef = React.createRef(null);
    this.state = {
      hideGraph: false,
      chartType: "candle",
    };
    this.clickBar = this.clickBar.bind(this);
    this.clickCandle = this.clickCandle.bind(this);
    this.clickLine = this.clickLine.bind(this);
    this.clickArea = this.clickArea.bind(this);
  }

  componentDidMount() {
    const chart = createChart(document.getElementById("chartDiv"), {
      layout: {
        backgroundColor: "#ffffff",
        textColor: "rgba(33, 56, 77, 1)",
      },
      rightPriceScale: {
        borderColor: "rgba(11, 127, 185, 1)",
      },
      timeScale: {
        borderColor: "rgba(11, 127, 185, 1)",
      },
    });

    const candlestickSeries = chart.addCandlestickSeries();
      candlestickSeries.setData([
        {
          time: "2023-02-22",
          open: 0.16,
          high: 0.84,
          low: 0.16,
          close: 0.72,
        },
        {
          time: "2023-02-23",
          open: 0.02,
          high: 0.9,
          low: 0.02,
          close: 0.09,
        },
        {
          time: "2023-02-24",
          open: 0.71,
          high: 0.71,
          low: 0.39,
          close: 0.29,
        },
        {
          time: "2023-02-25",
          open: 0.26,
          high: 0.26,
          low: 0.04,
          close: 0.5,
        },
        {
          time: "2023-02-26",
          open: 0.71,
          high: 0.85,
          low: 0.67,
          close: 0.04,
        },
        {
          time: "2023-02-27",
          open: 0.04,
          high: 0.4,
          low: 0.7,
          close: 0.4,
        },
        {
          time: "2023-02-28",
          open: 0.51,
          high: 0.83,
          low: 0.34,
          close: 0.25,
        },
        {
          time: "2023-02-29",
          open: 0.33,
          high: 0.17,
          low: 0.68,
          close: 0.43,
        },
        {
          time: "2023-02-30",
          open: 0.33,
          high: 0.2,
          low: 0.39,
          close: 0.1,
        },
        {
          time: "2023-02-31",
          open: 0.87,
          high: 0.69,
          low: 0.66,
          close: 0.26,
        },
      ]);

      chart.timeScale().fitContent();
  }

  clickCandle() {
    this.setState({chartType:'candle'})
    document.getElementById("chartDiv").innerHTML = "";
    const chart = createChart(document.getElementById("chartDiv"), {
      layout: {
        backgroundColor: "#ffffff",
        textColor: "rgba(33, 56, 77, 1)",
      },
      rightPriceScale: {
        borderColor: "rgba(11, 127, 185, 1)",
      },
      timeScale: {
        borderColor: "rgba(11, 127, 185, 1)",
      },
    });
    const candlestickSeries = chart.addCandlestickSeries();

    candlestickSeries.setData([
      {
        time: "2023-02-22",
        open: 0.16,
        high: 0.84,
        low: 0.16,
        close: 0.72,
      },
      {
        time: "2023-02-23",
        open: 0.02,
        high: 0.9,
        low: 0.02,
        close: 0.09,
      },
      {
        time: "2023-02-24",
        open: 0.71,
        high: 0.71,
        low: 0.39,
        close: 0.29,
      },
      {
        time: "2023-02-25",
        open: 0.26,
        high: 0.26,
        low: 0.04,
        close: 0.5,
      },
      {
        time: "2023-02-26",
        open: 0.71,
        high: 0.85,
        low: 0.67,
        close: 0.04,
      },
      {
        time: "2023-02-27",
        open: 0.04,
        high: 0.4,
        low: 0.7,
        close: 0.4,
      },
      {
        time: "2023-02-28",
        open: 0.51,
        high: 0.83,
        low: 0.34,
        close: 0.25,
      },
      {
        time: "2023-02-29",
        open: 0.33,
        high: 0.17,
        low: 0.68,
        close: 0.43,
      },
      {
        time: "2023-02-30",
        open: 0.33,
        high: 0.2,
        low: 0.39,
        close: 0.1,
      },
      {
        time: "2023-02-31",
        open: 0.87,
        high: 0.69,
        low: 0.66,
        close: 0.26,
      },
    ]);
    chart.timeScale().fitContent();
  }

  clickBar() {
    this.setState({chartType:'bars'})

    document.getElementById("chartDiv").innerHTML = "";
    const chart = createChart(document.getElementById("chartDiv"), {
      // width: 600,
      // height: 300,
      layout: {
        backgroundColor: "#ffffff",
        textColor: "rgba(33, 56, 77, 1)",
      },
      rightPriceScale: {
        borderColor: "rgba(197, 203, 206, 1)",
      },
      timeScale: {
        borderColor: "rgba(197, 203, 206, 1)",
      },
    });

    const barSeries = chart.addBarSeries({
      thinBars: true,
      downColor: "#000",
      upColor: "#000",
    });

    barSeries.setData([
      {
        time: "2023-02-22",
        open: 0.16,
        high: 0.84,
        low: 0.16,
        close: 0.72,
      },
      {
        time: "2023-02-23",
        open: 0.02,
        high: 0.9,
        low: 0.02,
        close: 0.09,
      },
      {
        time: "2023-02-24",
        open: 0.71,
        high: 0.71,
        low: 0.39,
        close: 0.29,
      },
      {
        time: "2023-02-25",
        open: 0.26,
        high: 0.26,
        low: 0.04,
        close: 0.5,
      },
      {
        time: "2023-02-26",
        open: 0.71,
        high: 0.85,
        low: 0.67,
        close: 0.04,
      },
      {
        time: "2023-02-27",
        open: 0.04,
        high: 0.4,
        low: 0.7,
        close: 0.4,
      },
      {
        time: "2023-02-28",
        open: 0.51,
        high: 0.83,
        low: 0.34,
        close: 0.25,
      },
      {
        time: "2023-02-29",
        open: 0.33,
        high: 0.17,
        low: 0.68,
        close: 0.43,
      },
      {
        time: "2023-02-30",
        open: 0.33,
        high: 0.2,
        low: 0.39,
        close: 0.1,
      },
      {
        time: "2023-02-31",
        open: 0.87,
        high: 0.69,
        low: 0.66,
        close: 0.26,
      },
    ]);
    chart.timeScale().fitContent();
  }

  clickLine() {
    this.setState({chartType:'line'})

    document.getElementById("chartDiv").innerHTML = "";
    const chart = createChart(document.getElementById("chartDiv"), {
      // width: 600,
      // height: 300,
      rightPriceScale: {
        borderVisible: false,
      },
      timeScale: {
        borderVisible: false,
      },
    });

    const lineSeries = chart.addLineSeries({ color: "#2962FF" });

    lineSeries.setData([
      {
        time: "2023-02-22",
        value: 0.16,
      },
      {
        time: "2023-02-23",
        value: 0.02,
      },
      {
        time: "2023-02-24",
        value: 0.71,
      },
      {
        time: "2023-02-25",
        value: 0.26,
      },
      {
        time: "2023-02-26",
        value: 0.71,
      },
      {
        time: "2023-02-27",
        value: 0.04,
      },
      {
        time: "2023-02-28",
        value: 0.51,
      },
      {
        time: "2023-02-29",
        value: 0.33,
      },
      {
        time: "2023-02-30",
        value: 0.33,
      },
      {
        time: "2023-02-31",
        value: 0.87,
      },
    ]);
    chart.timeScale().fitContent();
  }

  clickArea() {
    this.setState({chartType:'area'})

    document.getElementById("chartDiv").innerHTML = "";
    const chart = createChart(document.getElementById("chartDiv"), {
      // width: 600,
      // height: 300,
      rightPriceScale: {
        borderVisible: false,
      },
      timeScale: {
        borderVisible: false,
      },
    });

    const areaSeries = chart.addAreaSeries({
      topColor: "rgba(33, 150, 243, 0.56)",
      bottomColor: "rgba(33, 150, 243, 0.04)",
      lineColor: "rgba(33, 150, 243, 1)",
      lineWidth: 2,
    });

    areaSeries.setData([
      {
        time: "2023-02-22",
        value: 0.16,
      },
      {
        time: "2023-02-23",
        value: 0.02,
      },
      {
        time: "2023-02-24",
        value: 0.71,
      },
      {
        time: "2023-02-25",
        value: 0.26,
      },
      {
        time: "2023-02-26",
        value: 0.71,
      },
      {
        time: "2023-02-27",
        value: 0.04,
      },
      {
        time: "2023-02-28",
        value: 0.51,
      },
      {
        time: "2023-02-29",
        value: 0.33,
      },
      {
        time: "2023-02-30",
        value: 0.33,
      },
      {
        time: "2023-02-31",
        value: 0.87,
      },
    ]);
    chart.timeScale().fitContent();
  }

  render() {
    const { hideGraph, chartType } = this.state;
    const { selectedClob, dummyData } = this.props;

    return (
      <>
        <Grid
          container
          direction="row"
          sx={{ paddingTop: "0px" }}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            sx={{ borderBottom: "2px solid #48466d", lineHeight: "2em" }}
          >
            <Typography sx={{ display: { xs: "none", sm: "inline" } }}>
              ALGO-USD
            </Typography>
            <Box component="div" sx={{ display: { xs: "inline", sm: "none" } }}>
              <Box
                component="img"
                src={algoLogo}
                sx={{
                  width: "18px",
                  display: "inline",
                  verticalAlign: "middle",
                }}
              />
              <Menu fontSize="small" sx={{ verticalAlign: "middle" }} />
              <Box
                component="img"
                src={usdIcon}
                sx={{
                  width: "15px",
                  display: "inline",
                  verticalAlign: "middle",
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            sx={{
              borderBottom: "2px solid #48466d",
              borderLeft: "2px solid #48466d",
              lineHeight: "2em",
            }}
          >
            <Typography sx={{ "&.MuiTypography-root": { lineHeight: "2em" } }}>
              {dummyData[0].price} $
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            sx={{
              borderBottom: "2px solid #48466d",
              borderLeft: "2px solid #48466d",
            }}
          >
            <Typography
              sx={{
                "&.MuiTypography-root": {
                  lineHeight: { xs: "3.2em", sm: "2em" },
                },
                fontSize: { xs: "10px", sm: "16px" },
              }}
            >
              {selectedClob.maturity}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            sx={{
              borderBottom: "2px solid #48466d",
              borderLeft: "2px solid #48466d",
            }}
          >
            <Typography sx={{ "&.MuiTypography-root": { lineHeight: "2em" } }}>
              {selectedClob.strike} $
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            sx={{
              borderBottom: "2px solid #48466d",
              borderLeft: "2px solid #48466d",
              lineHeight: "2em",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => this.setState({ hideGraph: !hideGraph })}
              sx={buttonSx}
              size="small"
            >
              {!hideGraph ? "Hide Graph" : "Show Graph"}
            </Button>
          </Grid>
        </Grid>

     

        <Collapse in={!hideGraph}>
        <ButtonGroup variant="contained" sx={{ marginTop: "10px" }}>
          <Button sx={chartType === 'candle' ? activeButtonSx :buttonSx} size="small" onClick={this.clickCandle}>
            Candle
          </Button>
          <Button sx={chartType === 'line' ? activeButtonSx :buttonSx} size="small" onClick={this.clickLine}>
            Line
          </Button>
          <Button sx={chartType === 'bars' ? activeButtonSx :buttonSx} size="small" onClick={this.clickBar}>
            Bars
          </Button>
          <Button sx={chartType === 'area' ? activeButtonSx :buttonSx} size="small" onClick={this.clickArea}>
            Area
          </Button>
        </ButtonGroup>
          <Grid item xs={12} sm={12} md={12}>
            <Box
              component="div"
              id="chartDiv"
              sx={{
                width: { xs: "100%", sm: "50%", md: "100%" },
                height: { xs: "405px", sm: "405px" },
      
              }}
            />
          </Grid>
        </Collapse>
      </>
    );
  }
}
ChartComponent.propTypes = {
  selectedClob: PropTypes.object.isRequired,
  dummyData: PropTypes.array.isRequired,
};
export default ChartComponent;
