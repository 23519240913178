import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";



class OpenOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { bidData, askData,walletAddress } = this.props;

    return (
      <>
        <TableContainer >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Price
                </TableCell>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Siz
                </TableCell>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bidData.filter(data => data.walletAddress === walletAddress).map((data, index) => (
                <TableRow key={index}>
                  <TableCell align="left" component="th" scope="row">
                    {data.price}
                  </TableCell>

                  <TableCell align="left">{data.size}</TableCell>
                  <TableCell align="left">{(data.size * data.price).toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
OpenOrders.propTypes = {
  bidData: PropTypes.array.isRequired,
  askData: PropTypes.array.isRequired,
  walletAddress: PropTypes.string.isRequired,
};
export default OpenOrders;
