import React from "react";

import { Grid, Typography, Card, CardContent, Box } from "@mui/material";

const cardSx = {
  cursor: "pointer",
  "&:hover": {
    color: "#0081c6",
    backgroundColor: "#48466d",
    height: "215px",
    "& .cardContent": {
      color: "#fff",
    },
    "& .cardSubtitle": {
      color: "#afb0ae",
    },
  },
};
class Features extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid item xs={12} sm={12} md={12} style={{ padding: "30px" }}>
        <Typography variant="h3" sx={{ marginTop: "5%", marginBottom: "20px" }}>
          Features
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={cardSx} elevation={2}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  className="cardSubtitle"
                >
                  Powered by Algorand
                </Typography>
                <Typography variant="h5" component="div">
                  Algorand
                </Typography>
                <br />
                <Typography
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                  className="cardContent"
                >
                  Robust, fast, green, no-fork, efficient, low-cost and amazing.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={cardSx}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  className="cardSubtitle"
                >
                  Options and futures
                </Typography>
                <Typography variant="h5" component="div">
                  All Derivatives
                </Typography>
                <br />
                <Typography
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                  className="cardContent"
                >
                  All derivatives markets DEX in one Algorand dApp using AVM
                  latest features
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={cardSx}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  className="cardSubtitle"
                >
                  App, Service, API and SDK
                </Typography>
                <Typography variant="h5" component="div">
                  Derivatives Everywhere
                </Typography>
                <br />
                <Typography
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                  className="cardContent"
                >
                  AlgoDerivatives will be available everywhere and devoted to
                  both UX and DX.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={cardSx}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  className="cardSubtitle"
                >
                  Powered by Goracle
                </Typography>
                <Typography variant="h5" component="div">
                  Oracles
                </Typography>
                <br />
                <Typography
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                  className="cardContent"
                >
                  AlgoDerivatives is seamlessly integrated and fed from price
                  paires directly from Algorand assets.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
export default Features;
