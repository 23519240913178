import React, { Component } from "react";
import PropTypes from "prop-types";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { AppBar, Toolbar, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "../assets/images/logo.png";
import Wallet from "./Wallet";

const styles = {
  appbar: `
    padding: "10px 10px 10px 10px";`,
  logoImg: ` width: 15%;`,
  discordImg: `width: 26px;
  margin-left: 13px;
  vertical-align:middle;
  `,
};
const logoSx={
  cursor:'pointer',
  width:{
    xl: "16%",
    lg: "16%",
    md: "15%",
    sm: "45%",
    xs: "53%",
  }
}

const appbarSx = {
  "&.MuiAppBar-root": {
    backgroundColor: "#ffffff",
    color: "#1b1c1b",
  },
};

const buttonSx = {
  marginLeft: "8px",
  "&.MuiButton-root": {
    backgroundColor: "#0081c6",
    color: "#fff",
  },
  "&:hover": {
    backgroundColor: "#48466d",
    color: "#fff",
  },
  marginTop:{
    xs:'10px'
  },
  width:{
    md:'100%',
    xs: '68%'
  }

};
const appButtonSx = {
  marginLeft: "8px",
  "&.MuiButton-root": {
    backgroundColor: "#0081c6",
    color: "#fff",
  },
  "&:hover": {
    backgroundColor: "#48466d",
    color: "#fff",
  },
  marginRight:{
    xs:'16px',
    md:'16px'
  }
};

const useDesktopMenu = () => {
  return useMediaQuery(useTheme().breakpoints.up('sm'));
};

function DesktopMenuComponent() {
  if (useDesktopMenu()) {
    return (
      <div>
        <Button
          variant="contained"
          href="https://docs.algoderivatives.io"
          target="_blank"
          sx={appButtonSx}
        >
          Docs
        </Button>
        <Button
          variant="contained"
          href="https://algoderivatives.medium.com"
          target="_blank"
          sx={appButtonSx}
        >
          Medium
        </Button>
      </div>
    );
  }
  return null;
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.handleClickLogo = this.handleClickLogo.bind(this);
  }

  handleClickLogo(){
    this.props.setIsWalletConnected(false);
    this.props.setIsLandingPageView(true); 
    console.log('isWalletConnected:',this.props.walletConnected)
  }
  
  render() {
    return (
      <>
        <AppBar
          position="static"
          css={css`
            ${styles.appbar}
          `}
          elevation={0}
          sx={appbarSx}
        >
          <Toolbar sx={{paddingRight:{xs:'0px',md:'16px'}}}>
            <Box
              component="img"
              src={logo}
              onClick={this.handleClickLogo}
              sx={logoSx}
              alt="AlgoDerivatives"
            />
            <Box sx={{ flexGrow: 1 }}>
              <DesktopMenuComponent />
            </Box>
            <Box sx={{display:{md:'flex'}}}>
              {(this.props.walletAddress=== '' && !this.props.walletConnected) ? (
                <Button
                  variant="contained"
                  //onClick={() => this.props.setIsLandingPageView(false)}
                  sx={appButtonSx}
                  href="https://app.algoderivatives.io"
                  target="_blank"
                >
                  Go to App
                </Button>
              ) : <Box sx={{display:{xs:'none',sm:'block'}}}><Wallet balance={this.props.balance} walletAddress={this.props.walletAddress} /></Box>}
            </Box>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}
Header.propTypes = {
  setIsLandingPageView: PropTypes.func.isRequired,
  setIsWalletConnected: PropTypes.func.isRequired,
  walletConnected: PropTypes.bool.isRequired,
  walletAddress: PropTypes.string.isRequired,
  balance: PropTypes.number,

  
};
export default Header;
