import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Grid, Typography, Box } from "@mui/material";

const styles = {
  title: `
      text-align: center;
      width: 70%;
      margin: auto
        `,
};

class Details extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <div
          css={css`
            ${styles.title}
          `}
        >
          <Typography variant="h3" sx={{ marginTop: "5%" }}>
            What is AlgoDerivatives?
          </Typography>
          <br />
          <Typography
            variant="h6"
            sx={{ marginTop: "5%", textAlign: "center" }}
          >
            Build the Reference Derivatives Decentralized Exchange for Options
            on the ALGORAND Blockchain. Offer Fast, Efficient and Secure Option
            Transactions On-Chain for Individuals and Institutions alike.
          </Typography>
          <br />
        </div>
        <br />
      </Grid>
    );
  }
}
export default Details;
