import React from "react";
import { toDataURL } from "qrcode";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Tooltip,
  IconButton,
  Alert
} from "@mui/material";
import { FileCopyOutlined, DashboardOutlined } from "@mui/icons-material";
import { PropTypes } from "prop-types";

const alertSx = {
  "&.MuiAlert-message": {
    wordBreak: "break-all",
  },
  textAlign: {
    xs: "justify",
  },

  "&.MuiAlert-root": {
    textAlign: "left",
  },
  margin: "10px",
};

const gridItemSx = {
  "& .MuiGrid-root": {
    paddingRight: "24px",
  },
  textAlign: "center",
  marginTop: "25px",
};

const qrSx = {
  width: "256px",
  height: "256px",
  margin: "auto",
  display: "block",
};

class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWalletQrModalOpen: false,
      isAlertDialogOpen: false,
    };

    this.generateWalletQRCode = this.generateWalletQRCode.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  generateWalletQRCode() {
    let { label, inverse, version, margin, errorLevel, lightColor, darkColor } =
      this.state;
    let { walletAddress } = this.props;
    const that = this;
    const errorCorrectionLevel = errorLevel;
    const color = { light: lightColor, dark: darkColor };

    const opts = {
      inverse,
      version,
      margin,
      errorCorrectionLevel,
      color,
    };
    let algorandURI = "";

    algorandURI = `${walletAddress}?label=${label}`;

    opts.mode = "Auto";
    toDataURL(algorandURI, opts)
      .then((res) => {
        this.setState({ walletDataURL: res, walletUri: algorandURI }, () => {
          that.setState({ isWalletQrModalOpen: true });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  handleCloseDialog() {
    this.setState({
      isWalletQrModalOpen: false,
    });
  }

  render() {
    const { isWalletQrModalOpen, isAlertDialogOpen } = this.state;
    const { walletAddress } = this.props;
    return (
      <>
        <Dialog
          open={isWalletQrModalOpen}
          onClose={() =>
            this.setState({
              isWalletQrModalOpen: false,
            })
          }
          aria-labelledby="qr-dialog-title"
          aria-describedby="qr-dialog-description"
        >
          <DialogTitle id="qr-dialog-title">Address QRcode</DialogTitle>
          <DialogContent>
            <img alt="Wallet QR" sx={qrSx} src={this.state.walletDataURL} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({
                  isWalletQrModalOpen: false,
                })
              }
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isAlertDialogOpen}
          onClose={() =>
            this.setState({
              isAlertDialogOpen: false,
            })
          }
        >
          <DialogContent>
          <Alert severity="info" sx={alertSx}>
            {"Copied the wallet address: " + walletAddress}
          </Alert>
          </DialogContent>

        </Dialog>
 

        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={2} sm={4} md={2}></Grid>
          <Grid
            item
            xs={10}
            sm={8}
            md={10}
            container
            sx={{
              padding: {
                sm: "5px",
                xs: "16px",
              },
            }}
            direction="row"
          >
            <Grid item xs sx={gridItemSx}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  textAlign: { sm: "right" },
                  color: "#000000",
                }}
              >
                Balance: {Number(this.props.balance).toPrecision(7)} Algo
              </Typography>
            </Grid>
            <Grid item xs>
              <TextField
                size="small"
                id="wallet"
                label="Selected wallet"
                variant="outlined"
                value={this.props.walletAddress.substring(0, 10) + "..."}
                sx={{
                  // minWidth: "25%",
                  width: {
                    // xl: "90%px",
                    // lg: "89%px",
                    // md: "74%",
                    // sm: "85%",
                    // xs: "100%",
                  },
                  verticalAlign: "middle",
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#0081c6",
                  },
                }}
                margin="normal"
                InputProps={{
                  readOnly: true,

                  endAdornment: (
                    <>
                      <Tooltip title="Copy wallet address">
                        <IconButton
                          onClick={() => {
                            this.setState(
                              { isAlertDialogOpen: true },
  
                              () => {
                                navigator.clipboard.writeText(walletAddress);
  
                                setTimeout(
                                  () =>
                                    this.setState({ isAlertDialogOpen: false }),
                                  5000
                                );
                              }
                            );
                          }}
                          edge="end"
                        >
                          <FileCopyOutlined
                            fontSize="small"
                            style={{
                              color: "#0081c6",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Show QRCode">
                        <IconButton
                          onClick={() => {
                            this.generateWalletQRCode();
                          }}
                          edge="end"
                        >
                          <DashboardOutlined
                            fontSize="small"
                            style={{
                              color: "#0081c6",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ),
                }}
                InputLabelProps={{
                  style: { color: "#fff", fontSize: "12px" },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
Wallet.propTypes = {
  balance: PropTypes.number,
  walletAddress: PropTypes.string,
};
export default Wallet;
