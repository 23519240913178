import React from "react";

import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const dummyData = [
  {
    price: "0.30",
    size: 10,
    time: '02/11/2023',
  },
  {
    price: "0.32",
    size: 15,
    time: '02/11/2023',
  },
];

class RecentTrades extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>

        <TableContainer>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
               
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Price
                </TableCell>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Siz
                </TableCell>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Time
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dummyData.map((data,index) => (
                <TableRow key={index}>

                  <TableCell align="left">{data.price}</TableCell>
                  <TableCell align="left">{data.size}</TableCell>
                  <TableCell align="left">{data.time}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
RecentTrades.propTypes = {};
export default RecentTrades;
