import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
} from "@mui/material";

const buttonSx = {
  "&.MuiButtonGroup-root": {
    backgroundColor: "#0081c6",
    color: "#fff",
  },
  "&.MuiButton-root": {
    backgroundColor: "#0081c6",
    color: "#fff",
  },
  "&:hover": {
    backgroundColor: "#48466d",
    color: "#fff",
  },
  // marginTop: {
  //   xs: "10px",
  // },
};
const selectedTableRowSx = {
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: "#82b7f5",
    "& > .MuiTableCell-root": {
      color: "#fff",
    },
  },
};

class OrderBooks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { clobData, setSelectedClob, selectedClob } = this.props;
    return (
      <>
        <Grid
          container
          direction="row"
          // sx={{
          //     border: "1px solid #56f" ,
          //     marginTop: '10px'}}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={4}
            sm={4}
            md={4}

            //   sx={{ border: "1px solid #e08" }}
          >
            <ButtonGroup variant="contained" sx={{ marginTop: "10px" }}>
              <Button sx={buttonSx} size="small">
                Options
              </Button>
              <Button sx={buttonSx} size="small">
                Futures
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid
            item
            xs={5}
            sm={4}
            md={4}

            //   sx={{ border: "1px solid #e08" }}
          >
            <ButtonGroup variant="contained" sx={{ marginTop: "10px" }}>
              <Button sx={buttonSx} size="small">
                Call
              </Button>
              <Button sx={buttonSx} size="small">
                Put
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid
            item
            xs={3}
            sm={4}
            md={4}

            //   sx={{ border: "1px solid #e08" }}
          >
            <Button
              variant="contained"
              // onClick={}
              sx={{
                "&.MuiButtonGroup-root": {
                  backgroundColor: "#0081c6",
                  color: "#fff",
                },
                "&.MuiButton-root": {
                  backgroundColor: "#0081c6",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#48466d",
                  color: "#fff",
                },
                marginTop: "10px",
              }}
              size="small"
            >
              Expiring
            </Button>
          </Grid>
        </Grid>

        <TableContainer sx={{ marginTop: "3%" }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Strike
                </TableCell>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Maturity
                </TableCell>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Type
                </TableCell>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  BID
                </TableCell>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  ASK
                </TableCell>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Total Algo
                </TableCell>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Total USDC
                </TableCell>
                <TableCell align="left" sx={{ color: "#b2b1b1" }}>
                  Position
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clobData.map((clob) => (
                <TableRow
                  key={clob.id}
                  sx={selectedTableRowSx}
                  selected={selectedClob && selectedClob.id === clob.id}
                  onClick={() => setSelectedClob(clob)}
                >
                  <TableCell align="left" component="th" scope="row">
                    {clob.strike} $
                  </TableCell>

                  <TableCell align="left">{clob.maturity}</TableCell>
                  <TableCell align="left">{clob.type}</TableCell>
                  <TableCell align="left">{clob.bid.length}</TableCell>
                  <TableCell align="left">{clob.ask.length}</TableCell>
                  <TableCell align="left">{clob.totalAlgo} Algo</TableCell>
                  <TableCell align="left">{clob.totalUsdc} $</TableCell>
                  <TableCell align="left">
                    {(clob.id === selectedClob.id && selectedClob.position !== "" ) ? (
                      <Chip
                        label={selectedClob.position === "long" ? "Long" : "Short"}
                        size="small"
                        sx={{
                          "&.MuiChip-sizeSmall": {
                            borderRadius: "3px",
                            border:
                            selectedClob.position === "long"
                                ? "1px solid #11b119"
                                : "1px solid #b11111",
                            color: selectedClob.position === "long" ? "#11b119" : "#b11111",
                          },
                        }}
                      />
                    ) : ( 
                    <Chip
                      label={selectedClob.position === "" && 'Neutral'}
                      size="small"
                      sx={{
                        "&.MuiChip-sizeSmall": {
                          borderRadius: "3px",
                          border:"1px solid #f5e610",
                          color: "#f5e610",
                        },
                      }}
                    />)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
OrderBooks.propTypes = {
  clobData: PropTypes.array.isRequired,
  setSelectedClob: PropTypes.func.isRequired,
  selectedClob: PropTypes.object.isRequired,
};
export default OrderBooks;
