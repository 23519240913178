import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import WebsocketHandler from "./WebSocketHandler";
import ChartComponent from "./ChartComponent";
import OrderBooks from "./OrderBooks";
import OrderBook from "./OrderBook";
import TradeActions from "./TradeActions";
import WalletTradeHistory from "./WalletTradeHistory";

class TradePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBid: {},
      selectedAsk: {},
      newOrderObj: {},
      orderType: "long",
      
      selectedClob: {
        id: "01",
        strike: 0.34,
        maturity: "02/11/2023",
        type: "put",
        position: "",
        bid: [
          {
            size: 22,
            price: 0.41,
            walletAddress:
              "JSDATKPNLIKNPMK45NE44B74KB6QHHKFMZOQM6VWGH7H4KNQ3TEH3LRXWY",
          },
          {
            size: 17,
            price: 0.22,
            walletAddress: "",
          },
        ],
        ask: [
          {
            size: 20,
            price: 0.31,
            walletAddress:
              "JSDATKPNLIKNPMK45NE44B74KB6QHHKFMZOQM6VWGH7H4KNQ3TEH3LRXWY",
          },
          {
            size: 27,
            price: 0.38,
            walletAddress:
              "JSDATKPNLIKNPMK45NE44B74KB6QHHKFMZOQM6VWGH7H4KNQ3TEH3LRXWY",
          },
          {
            size: 13,
            price: 0.12,
            walletAddress: "",
          },
          {
            size: 21,
            price: 0.36,
            walletAddress: "",
          },
        ],
        totalAlgo: 2,
        totalUsdc: 0,
      },
      clobData: [
        {
          id: "01",
          strike: 0.34,
          maturity: "02/11/2023",
          type: "put",
          position: "",
          bid: [
            {
              size: 22,
              price: 0.41,
              type: "call",
              walletAddress:
                "JSDATKPNLIKNPMK45NE44B74KB6QHHKFMZOQM6VWGH7H4KNQ3TEH3LRXWY",
            },
            {
              size: 17,
              price: 0.22,
              type: "call",
              walletAddress: "",
            },
          ],
          ask: [
            {
              size: 20,
              price: 0.31,
              type: "put",
              walletAddress:
                "JSDATKPNLIKNPMK45NE44B74KB6QHHKFMZOQM6VWGH7H4KNQ3TEH3LRXWY",
            },
            {
              size: 27,
              price: 0.38,
              type: "put",
              walletAddress:
                "JSDATKPNLIKNPMK45NE44B74KB6QHHKFMZOQM6VWGH7H4KNQ3TEH3LRXWY",
            },
            {
              size: 13,
              price: 0.12,
              type: "put",
              walletAddress: "",
            },
            {
              size: 21,
              price: 0.36,
              type: "put",
              walletAddress: "",
            },
          ],
          totalAlgo: 0,
          totalUsdc: 0,
        },
        {
          id: "02",
          strike: 0.35,
          maturity: "02/25/2023",
          type: "call",
          position: "",
          bid: [
            {
              size: 23,
              price: 0.17,
              walletAddress:
                "JSDATKPNLIKNPMK45NE44B74KB6QHHKFMZOQM6VWGH7H4KNQ3TEH3LRXWY",
            },
          ],
          ask: [
            {
              size: 20,
              price: 0.31,
              walletAddress:
                "JSDATKPNLIKNPMK45NE44B74KB6QHHKFMZOQM6VWGH7H4KNQ3TEH3LRXWY",
            },
            {
              size: 27,
              price: 0.38,
              walletAddress: "",
            },
            {
              size: 13,
              price: 0.12,
              walletAddress: "",
            },
          ],
          totalAlgo: 0,
          totalUsdc: 0,
        },
      ],
      dummyData: [{ price: 0.23, timestamp: "" }],
    };
    this.handler = this.handler.bind(this);
    this.createNewOrder = this.createNewOrder.bind(this);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.bidData !== prevState.bidData) {
  //     this.setState({
  //       bidData: this.state.bidData,
  //     });
  //   }
  // }

  handler(event) {
    // const { options, series } = this.state;
    // const data = JSON.parse(event);
    // let ts = data[0].timestamp;
    // console.log("WebsocketHandler is working",ts);
    // let price = data[0].price;
    // options.xaxis.categories.push(Date(ts));
    // const newSeries = [
    //   {
    //     name: "algo-usd-price",
    //     data: [...series[0].data],
    //   },
    // ];
    // newSeries[0].data.push(price);
    // this.setState({ options: options, series: newSeries });
  }
  createNewOrder() {
    const { orderType, newOrderObj, selectedClob } = this.state;

    let ordersArray = [];
    // this.setState({ selectedClob: { ...selectedClob, position: orderType } });

    newOrderObj["walletAddress"] = this.props.walletAddress;
    if (orderType === "long") {
      ordersArray = selectedClob.bid;
      ordersArray.unshift(newOrderObj);
      this.setState({ selectedClob: { ...selectedClob, bid: ordersArray ,position: orderType} });
    } else {
      ordersArray = selectedClob.ask;
      ordersArray.unshift(newOrderObj);
      this.setState({ selectedClob: { ...selectedClob, ask: ordersArray,position: orderType } });
    }
  }

  render() {
    const {
      orderType,
      selectedBid,
      selectedAsk,
      newOrderObj,
      dummyData,
      clobData,
      selectedClob,
    } = this.state;
    const { balance, walletAddress } = this.props;
    return (
      <>
        {/* <WebsocketHandler
          url="wss://algoracle-websocket-server.herokuapp.com?apiKey=2FSdf3gdQcXqPpVfWS6w79gq&assets=algo&curr=usd"
          onMessage={this.handler}
        /> */}
        <Grid
          container
          direction="row"
          sx={{ border: "2px solid #48466d", paddingLeft: "3px" }}
        >
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={6}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
              borderRight: { sm: "2px solid #48466d" },
              padding: "0px",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <ChartComponent
                dummyData={dummyData}
                selectedClob={selectedClob}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                borderTop: "2px solid #48466d",
                height: {
                  // sm: "46vh",
                  md: "40vh",
                },
              }}
            >
              <OrderBooks
                clobData={clobData}
                selectedClob={selectedClob}
                setSelectedClob={(obj) => this.setState({ selectedClob: obj })}
              />
            </Grid>
          </Grid>

          <Grid item container direction="column" xs={12} sm={12} md={6}>
            <Grid item container direction="row">
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                // sx={{ "&.MuiGrid-root": { height: "190px" } }}
              >
                <OrderBook
                  selectedBid={selectedBid}
                  selectedAsk={selectedAsk}
                  bidData={selectedClob.bid}
                  askData={selectedClob.ask}
                  setSelectedBid={(bid) => this.setState({ selectedBid: bid })}
                  setSelectedAsk={(ask) => this.setState({ selectedAsk: ask })}
                  setOrderType={(type) => this.setState({ orderType: type })}
                  setNewOrderObj={(obj) => this.setState({ newOrderObj: obj })}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{
                  borderLeft: { sm: "2px solid #48466d" },
                  borderTop: { xs: "2px solid #48466d", sm: "0px" },
                }}
              >
                <TradeActions
                  walletAddress={walletAddress}
                  selectedClob={selectedClob}
                  selectedBid={selectedBid}
                  selectedAsk={selectedAsk}
                  newOrderObj={newOrderObj}
                  setNewOrderObj={(obj) => this.setState({ newOrderObj: obj })}
                  orderType={orderType}
                  balance={balance}
                  dummyData={dummyData}
                  setOrderType={(type) => this.setState({ orderType: type })}
               
                  createNewOrder={() => this.createNewOrder()}
                  cancelNewOrder={() =>
                    this.setState({
                      newOrderObj: { price: 0, size: 0, type: "" },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid item sx={{ borderTop: "2px solid #48466d" }}>
              <WalletTradeHistory
                walletAddress={walletAddress}
                bidData={selectedClob.bid}
                askData={selectedClob.ask}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
TradePanel.propTypes = {
  balance: PropTypes.number.isRequired,
  walletAddress: PropTypes.string.isRequired,
};
export default TradePanel;
