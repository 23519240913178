import React from "react";
import myAlgowallet from "../assets/images/myAlgoWallet.png";
import peraWallet from "../assets/images/pera.png";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import algosdk from "algosdk";
import MyAlgo from "@randlabs/myalgo-connect";
import algoLogo from "../assets/images/algo.png";
import Header from "./Header";
import { Button, Typography, Card, CardContent,Box } from "@mui/material";
import { PropTypes } from "prop-types";
import Wallet from "./Wallet";
import TradePanel from "./TradePanel";

const buttonSx = {
  "&.MuiButton-root": {
    backgroundColor: "#0081c6",
    color: "#ffffff",
    width: {
      // xl: "40%",
      // lg: "40%",
      // md: "40%",
      // sm: "80%",
      // xs: "100%",
    },
    padding: "20px",
  },
  "&:hover": {
    backgroundColor: "#48466d",
    color: "#fff",
  },
  marginTop: "30px",
  marginRight: { md: "10px" },
};

const walletCardSx = {
  maxWidth: {
    xl: "40%",
    lg: "40%",
    md: "40%",
    sm: "50%",
    xs: "75%",
  },
  margin: "auto",
  marginTop: "10%",
  padding: {
    xl: "50px",
    lg: "50px",
    md: "50px",
    sm: "40px",
    xs: "31px",
  },
  backgroundColor: "#fff",
  marginBottom: { xl: "27%", lg: "26%", md: "25%", sm: "50%", xs: "65%" },
  border: "2px solid #48466d",
};

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walletObject: null,
      walletAddress: "",
      balance: 0,
      inverse: false,
      version: "auto",
      margin: 4,
      errorLevel: "high",
      darkColor: "#000",
      lightColor: "#FFF",
      label: "Wallet Name",
      walletDataURL: algoLogo,
      walletUri: "algorand://",
      isWalletQrModalOpen: false,
    };
    this.handleClickPeraWalletButton =
      this.handleClickPeraWalletButton.bind(this);
    this.handleClickMyAlgoWalletButton =
      this.handleClickMyAlgoWalletButton.bind(this);
    this.algorandWallet = null;
    this.fetchAlgoWalletInfo = this.fetchAlgoWalletInfo.bind(this);
  }
  componentDidMount() {
    console.log("HomePage component mounted");
  }

  handleClickPeraWalletButton() {
    this.connector = new WalletConnect({
      bridge: "https://bridge.walletconnect.org",
      qrcodeModal: QRCodeModal,
    });
    const that = this;
    if (!this.connector.connected) {
      this.connector.createSession();
    } else {
      this.connector.killSession();
    }
    this.connector.on("connect", async (error, payload) => {
      if (error) {
        console.error(error);
        return;
      }
      that.algorandWallet = that.connector;

      this.setState({ walletObject: that.algorandWallet });
      const { accounts } = payload.params[0];
      let wallet = accounts[0];
      this.props.setIsWalletConnected(true);

      if (algosdk.isValidAddress(accounts[0])) {
        that.setState({ walletAddress: wallet }, () => {
          that.fetchAlgoWalletInfo();
        });
      }
    });

    this.connector.on("session_update", (error, payload) => {
      if (error) {
        throw error;
      }

      const { accounts } = payload.params[0];

      that.setState({ walletAddress: accounts[0].address }, () => {
        that.fetchAlgoWalletInfo();
      });
    });

    this.connector.on("disconnect", (error, payload) => {
      this.algorandWallet = null;
      if (error) {
        console.error(error);
        return;
      }
    });
  }

  async handleClickMyAlgoWalletButton() {
    try {
      this.algorandWallet =
        this.algorandWallet === null ? new MyAlgo() : this.algorandWallet;
      const accounts = await this.algorandWallet.connect();
      this.setState({ walletObject: this.algorandWallet });

      this.setState({ walletAddress: accounts[0].address }, () => {
        this.fetchAlgoWalletInfo();
      });

      if (algosdk.isValidAddress(accounts[0].address)) {
        this.setState({ walletAddress: accounts[0].address });
      }
      this.props.setIsWalletConnected(true);
    } catch (err) {
      console.error(err);
    }
  }

  fetchAlgoWalletInfo() {
    const { walletAddress } = this.state;
    const that = this;
    if (algosdk.isValidAddress(walletAddress)) {
      const url = `https://algoindexer.testnet.algoexplorerapi.io/v2/accounts/${walletAddress}`;
      const urlTrx = `https://algoindexer.testnet.algoexplorerapi.io/v2/accounts/${walletAddress}/transactions?limit=10`;
      window
        .fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.account) {
              if (String(data.account.address) === String(walletAddress)) {
                that.setState({
                  balance: data.account.amount / 1000000,
                });

                console.log(
                  "check algo balance:",
                  data.account.amount / 1000000
                );
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      window
        .fetch(urlTrx, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.transactions) {
              that.setState({
                trxPayment: data.transactions.filter(
                  (trx) => !!trx["payment-transaction"]
                ),
                trxTransfer: data.transactions.filter(
                  (trx) => !!trx["asset-transfer-transaction"]
                ),
              });
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  render() {
    const { walletAddress, balance } = this.state;
    return (
      <>
        <Header walletAddress={walletAddress} balance={balance} />
        {walletAddress === "" ? (
          <Card sx={walletCardSx}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{ marginBottom: "8%", color: "#48466d" }}
              >
                Connect your wallet
              </Typography>
              <Button
                variant="contained"
                size="large"
                sx={buttonSx}
                onClick={this.handleClickPeraWalletButton}
              >
                <img
                  src={peraWallet}
                  width={23}
                  style={{
                    verticalAlign: "bottom",
                    marginRight: 10,
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  alt="Pera wallet"
                />
                Pera wallet
              </Button>
              <Button
                variant="contained"
                size="large"
                sx={buttonSx}
                onClick={this.handleClickMyAlgoWalletButton}
              >
                <img
                  src={myAlgowallet}
                  width={27}
                  style={{ verticalAlign: "bottom", cursor: "pointer" }}
                  alt="My Algo wallet"
                />
                MyAlgo Wallet
              </Button>
            </CardContent>
          </Card>
        ) : (
          <>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <Wallet balance={balance} walletAddress={walletAddress} />
            </Box>
            <TradePanel balance={balance} walletAddress={walletAddress} />
          </>
        )}
      </>
    );
  }
}
HomePage.propTypes = {
  setIsWalletConnected: PropTypes.func,
};
export default HomePage;
