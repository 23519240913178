import React from "react";
import PropTypes from "prop-types";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography, IconButton, Grid, Box } from "@mui/material";
import { Twitter, GitHub,Telegram } from "@mui/icons-material";
import discord from "../assets/images/discord.png";

const styles = {
  footer: `position: fixed;
        background-color:#ffffff,
        bottom: 0;
        width: 100%;
        padding-bottom:10;
        zIndex:40;
        text-align: center;
       
        `,

  footerText: `color: #1c1c1c;
        lineHeight: 1;
        padding-bottom:10px`,
  discordImg: `width: 26px;
        vertical-align: middle;
        margin-left: 15px;
        cursor:pointer;
      
        `,
};

const iconBtnSx = {
  color: "#1c1c1c",
  "&:hover": {
    color: "#0081c6",
  },
};

const boxSx = {
  position: "absolute",
  bottom: 0,
  width: "100%",
};

export default function Footer(props) {
  return (

      <Box>
        <IconButton
          target="_blank"
          href="https://twitter.com/AlgoDerivatives"
          sx={iconBtnSx}
        >
          <Twitter sx={iconBtnSx} />
        </IconButton>
        <IconButton
          target="_blank"
          href="https://t.me/+6OWwq2YaPKFjYTY9"
          sx={iconBtnSx}
        >
          <Telegram sx={iconBtnSx} />
        </IconButton>

        <IconButton
          target="_blank"
          href="https://github.com/AlgoDerivatives"
          sx={iconBtnSx}
        >
          <GitHub sx={iconBtnSx} />
        </IconButton>

        <a
          target="_blank"
          //   href=""
          rel="noopener noreferrer"
        >
          <img
            alt="discord"
            target="_blank"
            href="https://discord.gg/7VDCmt6F"
            src={discord}
            css={css`
              ${styles.discordImg}
            `}
          />
        </a>

        <br />

        <Typography
          variant="body1"
          css={css`
            ${styles.footerText}
          `}
        >
          ©2022 by AlgoDerivatives
        </Typography>
      </Box>
    
  );
}

Footer.propTypes = {
};
