import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";

const selectedTableRowSx = {
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: "#82b7f5",
    "& > .MuiTableCell-root": {
      color: "#fff",
    },
  },
};
const containerSx = {
  height: { xs: "25vh", sm: "23vh" },
  maxHeight: { xs: "26vh", sm: "35vh" },
  minHeight: { xs: "15vh", sm: "21vh" },
  overflowY: "auto",
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "3px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 5px grey",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#808080",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#808070",
  },
  scrollbarColor: "#808080",
  scrollbarWidth: "thin",
};
class OrderBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      bidData,
      askData,
      selectedBid,
      selectedAsk,
      setSelectedBid,
      setSelectedAsk,
      setOrderType,
      setNewOrderObj,
    } = this.props;
    return (
      <>
        <Typography variant="h6" sx={{ color: "#48466d" }}>
          Order Book
        </Typography>
        <Grid
          container
          sx={{ borderTop: "2px solid #48466d" }}
          direction="column"
        >
                {/* Ask grid */}
                <Grid item xs={6} sm={6} md={6}>
            <TableContainer sx={containerSx}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ color: "#ffffff", backgroundColor: "#48466d" }}
                    >
                      Ask Price
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "#ffffff", backgroundColor: "#48466d" }}
                    >
                      Ask Size
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "#ffffff", backgroundColor: "#48466d" }}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {askData.map((ask, index) => (
                    <TableRow
                      key={index}
                      selected={ask === selectedAsk}
                      sx={selectedTableRowSx}
                      onClick={() => {
                        setSelectedAsk(ask);
                        setSelectedBid({});
                        setOrderType("short");
                        setNewOrderObj(ask);
                      }}
                    >
                      <TableCell align="left" component="th" scope="row">
                        {ask.price}
                      </TableCell>
                      <TableCell align="left">{ask.size}</TableCell>
                      <TableCell align="left">
                        {(ask.price * ask.size).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            sx={{ borderRight: "1px solid #48466d" }}
          >
            <TableContainer sx={containerSx}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ color: "#ffffff", backgroundColor: "#48466d" }}
                    >
                      Bid Price
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "#ffffff", backgroundColor: "#48466d" }}
                    >
                      Bid Size
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "#ffffff", backgroundColor: "#48466d" }}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bidData.map((bid, index) => (
                    <TableRow
                      selected={bid === selectedBid}
                      sx={selectedTableRowSx}
                      key={bid.index}
                      onClick={() => {
                        setSelectedBid(bid);
                        setSelectedAsk({});
                        setOrderType("long");
                        setNewOrderObj(bid);
                      }}
                    >
                      <TableCell align="left" component="th" scope="row">
                        {bid.price}
                      </TableCell>

                      <TableCell align="left">{bid.size}</TableCell>
                      <TableCell align="left">
                        {(bid.size * bid.price).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

    
        </Grid>
      </>
    );
  }
}
OrderBook.propTypes = {
  bidData: PropTypes.array.isRequired,
  askData: PropTypes.array.isRequired,
  selectedBid: PropTypes.object.isRequired,
  selectedAsk: PropTypes.object.isRequired,
  setSelectedBid: PropTypes.func.isRequired,
  setSelectedAsk: PropTypes.func.isRequired,
  setOrderType: PropTypes.func.isRequired,
  setNewOrderObj: PropTypes.func.isRequired,
};
export default OrderBook;
