
import './App.css';
import MainPage from './components/MainPage'


const algoDerivatives=(props)=>
  (<div className="App">
      <MainPage {...props} />
    </div>)


function App() {
  return algoDerivatives();
}

export default App;
