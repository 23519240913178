import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "styled-components";
import Banner from "./Banner";
import Features from "./Features";
import About from "./About";
import Details from "./Details";

import {
  Dialog,
  Button,
  DialogContent,
  Typography,
  Paper,
  Box,
} from "@mui/material";

const styles = {
  button: `
    `,
};

const boxSx = {
  backgroundColor: "#ffffff",
  color: "#000000",
};

const StyledPaper = styled(Paper)`
  background-color: #ffffff;
  color: #1b1c1b;
`;
const buttonSx = {
  "&.MuiButton-root": {
    backgroundColor: "#0081c6",
    color: "#fff",
    width: "20%",
    margin: "auto",
    marginBottom: { xs: "10px", md: "20px" },
  },
  "&:hover": {
    backgroundColor: "#48466d",
    color: "#fff",
  },
};

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: true,
    };
  }

  render() {
    const { dialogOpen } = this.state;
    return (
      <>
        <Dialog
          open={dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
          PaperComponent={StyledPaper}
        >
          <DialogContent sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: "bold",
                fontSize: {
                  xs: "16px",
                  sm: "17px",
                  md: "20px",
                  lg: "22px",
                  xl: "23px",
                },
              }}
              css={css`
                ${styles.button}
              `}
            >
              AlgoDerivatives is still WIP!
            </Typography>
          </DialogContent>{" "}
          <Button
            sx={buttonSx}
            onClick={() =>
              this.setState({
                dialogOpen: false,
              })
            }
          >
            OK
          </Button>{" "}
        </Dialog>
        <div style={{ overflowX: "hidden" }}>
          <Box sx={boxSx}>
            <Banner />
            <Features />
            <About />
            <Details />
          </Box>
        </div>
      </>
    );
  }
}
export default LandingPage;
