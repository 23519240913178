import React from "react";
/** @jsxImportSource @emotion/react */
import algorandVideo from "../assets/videos/algorandVideo.mp4";
import { css } from "@emotion/react";

import { Grid, Typography, Box } from "@mui/material";

const styles = {
  videoDiv: `
    width:100vw;
    position: relative;
    overflow:hidden
    `,
  video: `
    width: 100vw;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    transform: translateX(-50%) translateY(5%);
      `,
  title: `
      text-align: center;
      width: 80%;
      margin: auto
        `,
};

class About extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <div
          css={css`
            ${styles.title}
          `}
        >
          <Typography variant="h3" sx={{ marginTop: "5%" }}>
            Why Algorand?
          </Typography>
          <br />
          <Typography
            variant="h6"
            sx={{ marginTop: "5%", textAlign: "center" }}
          >
            Algorand was built as a green blockchain with an environmental
            impact focus from the very beginning. Algorand's founder Silvio
            Micali explained as far back as 2018 his focus on sustainability for
            our planet with next generation technology. With his unique
            consensus mechanism, Algorand is far more energy efficient than
            other blockchains, and is going further by offsetting its small
            carbon footprint in partnership with ClimateTrade.
          </Typography>
          <br />
          <a
            href="https://www.algorand.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#0081c6", textDecoration: "none" }}
          >
            (Videos curtesy of Algorand.com)
          </a>
        </div>

        <Box
          css={css`
            ${styles.videoDiv}
          `}
          sx={{
            height: {
              xl: "100vh",
              lg: "100vh",
              md: "100vh",
              sm: "270px",
              xs: "270px",
            },
          }}
        >
          <video
            autoPlay
            muted
            loop
            css={css`
              ${styles.video}
            `}
          >
            <source src={algorandVideo} type="video/mp4" />
          </video>
        </Box>
      </Grid>
    );
  }
}
export default About;
