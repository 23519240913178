import React from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Grid,
  FormControlLabel,
  FormControl,
  Chip,
  Slider,
  Box,
  Typography,
  ListItemText,
  Button,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const textFieldSx = {
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
};
const buttonSx = {
  marginLeft: "8px",
  "&.MuiButton-root": {
    backgroundColor: "#0081c6",
    color: "#fff",
  },
  "&:hover": {
    backgroundColor: "#48466d",
    color: "#fff",
  },
  marginTop: {
    xs: "10px",
  },
};

class ActionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
    };
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.selectedItem !== prevProps.selectedItem) {
  //     this.setState({
  //       ...this.state.newOrderObj,
  //       newOrderObj: this.props.selectedItem,
  //     });
  //   }
  // }

  render() {
    const {
      balance,
      dummyData,
      setNewOrderObj,
      newOrderObj,
      walletAddress,
      selectedClob,
      createNewOrder,
      cancelNewOrder,
      orderType,
    } = this.props;
    const marks = [
      {
        value: 0,
        label: "0%",
      },
      {
        value: 25,
        label: "25%",
      },
      {
        value: 50,
        label: "50%",
      },
      {
        value: 75,
        label: "75%",
      },
      {
        value: 100,
        label: "100%",
      },
    ];
    return (
      <>
        <Grid container>
          {/* price */}
          <Grid item xs={6} sm={6} md={6}>
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": { marginRight: "auto" },
                "& .MuiTypography-root": { fontWeight: "bold" },
              }}
              labelPlacement="top"
              control={
                <TextField
                  id="price"
                  // value={selectedItem.price || dummyData[0].price}
                  value={newOrderObj.price}
                  size="small"
                  type="number"
                  onChange={(e) =>
                    setNewOrderObj({
                      ...newOrderObj,
                      price: e.target.value,
                    })
                  }
                  sx={textFieldSx}
                />
              }
              label="Price"
            />
          </Grid>

          {/* select type */}
          <Grid item xs={6} sm={6} md={6}>
            <FormControl size="small">
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": { marginRight: "auto" },
                  "& .MuiTypography-root": { fontWeight: "bold" },
                }}
                labelPlacement="top"
                control={
                  <TextField
                    id="type"
                    disabled
                    sx={textFieldSx}
                    value={selectedClob.type}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                }
                label="Type"
              />
            </FormControl>
          </Grid>

          {/* size */}
          <Grid item xs={6} sm={6} md={6}>
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": { marginRight: "auto" },
                "& .MuiTypography-root": { fontWeight: "bold" },
              }}
              labelPlacement="top"
              control={
                <TextField
                  id="size"
                  sx={textFieldSx}
                  value={newOrderObj.size}
                  size="small"
                  type="number"
                  onChange={(e) => {
                    setNewOrderObj({
                      ...newOrderObj,
                      size: e.target.value,
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <Chip
                        label="Algo"
                        size="small"
                        sx={{ "&.MuiChip-sizeSmall": { borderRadius: "3px" } }}
                      />
                    ),
                  }}
                />
              }
              label="Size"
            />
          </Grid>

          {/* amount */}
          <Grid item xs={6} sm={6} md={6}>
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": { marginRight: "auto" },
                "& .MuiTypography-root": { fontWeight: "bold" },
              }}
              labelPlacement="top"
              control={
                <TextField
                  sx={textFieldSx}
                  id="amount"
                  value={
                    (newOrderObj.price * newOrderObj.size).toFixed(2) ||
                    this.state.amount
                  }
                  onChange={(e) => {
                    this.setState({ amount: e.target.value });

                    setNewOrderObj({
                      ...newOrderObj,
                      size: e.target.value / newOrderObj.price,
                    });
                  }}
                  size="small"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <Chip
                        label="USDC"
                        size="small"
                        sx={{ "&.MuiChip-sizeSmall": { borderRadius: "3px" } }}
                      />
                    ),
                  }}
                />
              }
              label="Amount"
            />
          </Grid>
        </Grid>

        {/* slider */}
        <Box component="div" sx={{ margin: "15px" }}>
          <Slider
            value={newOrderObj.size}
            onChange={(e) =>
              setNewOrderObj({ ...newOrderObj, size: e.target.value })
            }
            step={1}
            valueLabelDisplay="auto"
            marks={marks}
          />
        </Box>
        {/* <Box component="div" sx={{ margin: "15px", textAlign: "center" }}>
          <Typography
            variant="body1"
            sx={{ "&.MuiTypography-root": { fontWeight: "bold" } }}
          >
            Available Balance: {balance} Algo
          </Typography>
        </Box> */}

        <Box component="div" sx={{ margin: "15px", textAlign: "center" }}>
          <Grid container>
            {/* trade value */}
            <Grid item xs={6} sm={4} md={4}>
              <ListItemText
                sx={{
                  "& .MuiListItemText-secondary": {
                    fontWeight: "bold",
                    color: "#000",
                  },
                }}
                primary={
                  <>
                    <Typography
                      sx={{
                        display: "inline",
                        verticalAlign: "top",
                        color: "#494848",
                      }}
                      component="span"
                      variant="body1"
                    >
                      Trade value
                    </Typography>
                    <InfoOutlined fontSize="small" />
                  </>
                }
                secondary={
                  newOrderObj.size
                    ? (newOrderObj.price * newOrderObj.size).toFixed(2) +
                      `${" $"}`
                    : this.state.amount + `${" $"}`
                }
              />
            </Grid>

            {/* Estimated fees */}
            <Grid item xs={6} sm={4} md={4}>
              <ListItemText
                sx={{
                  "& .MuiListItemText-secondary": {
                    fontWeight: "bold",
                    color: "#000",
                  },
                }}
                primary={
                  <>
                    <Typography
                      sx={{
                        display: "inline",
                        verticalAlign: "top",
                        color: "#494848",
                      }}
                      component="span"
                      variant="body1"
                    >
                      Estimated fees
                    </Typography>
                    <InfoOutlined fontSize="small" />
                  </>
                }
                secondary={0.003}
              />
            </Grid>

            {/* Collatral */}
            <Grid item xs={6} sm={4} md={4}>
              <ListItemText
                sx={{
                  "& .MuiListItemText-secondary": {
                    fontWeight: "bold",
                    color: "#000",
                  },
                }}
                primary={
                  <>
                    <Typography
                      sx={{
                        display: "inline",
                        verticalAlign: "top",
                        color: "#494848",
                      }}
                      component="span"
                      variant="body1"
                    >
                      Collatral
                    </Typography>
                    <InfoOutlined fontSize="small" />
                  </>
                }
                secondary={
                  (newOrderObj.price * newOrderObj.size).toFixed(2) || 0
                }
              />
            </Grid>
          </Grid>
        </Box>
        {selectedClob.position === '' ?
          <Button
            sx={buttonSx}
            onClick={() => {
              createNewOrder();
            }}
          >
            Submit order
          </Button>
         :  <Button
        sx={buttonSx}
        // onClick={() => {createNewOrder()}}
      >
        Submit close order
      </Button>}
     

        {walletAddress === newOrderObj.walletAddress && (
          <Button sx={buttonSx} onClick={() => cancelNewOrder()}>
            cancel
          </Button>
        )}
      </>
    );
  }
}
ActionForm.propTypes = {
  walletAddress: PropTypes.string.isRequired,
  dummyData: PropTypes.array.isRequired,
  selectedClob: PropTypes.object.isRequired,
  newOrderObj: PropTypes.object.isRequired,
  orderType: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  createNewOrder: PropTypes.func.isRequired,
  cancelNewOrder: PropTypes.func.isRequired,
  setNewOrderObj: PropTypes.func.isRequired,
};
export default ActionForm;
