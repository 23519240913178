import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import bannerVideo from "../assets/videos/bannerVideo.mp4";
import bannerImage from "../assets/images/bannerImage.jpg";


import { Grid, Button, Typography, Box } from "@mui/material";

const styles = {
  videoDiv: `
    height:720px;
    width:100vw;
    position: relative;
    overflow:hidden;
    background-image: url(${bannerImage});
    background-size: cover
    `,
  video: `
    width: 100vw;
    height:100%;
    position: absolute;
    object-fit: cover;
    z-index: 2;
    transform: translateX(-50%) translateY(0%);
      `,
  videoCaption: `
    z-index: 3;
    position: relative;
    text-align: left;
    color: #0081c6;
    padding-top: 3%;
    padding-left: 120px;
    `,
  text: `
    padding-left: 30px`,
};

const buttonSx = {
  "&.MuiButton-root": {
    backgroundColor: "#fff",
    color: "#000000",
  },
  "&:hover": {
    backgroundColor: "#48466d",
    color: "#fff",
  },
  marginTTop: "30px",
};

class Banner extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <Box
          css={css`
            ${styles.videoDiv}
          `}
          sx={{
            height: {
              xl: "720px",
              lg: "700px",
              md: "510px",
              sm: "357px",
              xs: "390px",
            },
          }}
        >
          <video
            autoPlay
            muted
            loop
            css={css`
              ${styles.video}
            `}
          >
            <source src={bannerVideo} type="video/mp4" />
          </video>

          <Box
            css={css`
              ${styles.videoCaption}
            `}
            sx={{
              paddingLeft: {
                xl: "135px",
                lg: "130px",
                md: "120px",
                sm: "100px",
                xs: "30px",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "35px",
                  sm: "60px",
                  md: "80px",
                  lg: "80px",
                  xl: "80px",
                },
              }}
              css={css`
                ${styles.text}
              `}
            >
              Derivatives Markets
            </Typography>

            <Typography
              sx={{
                fontSize: {
                  xs: "35px",
                  sm: "60px",
                  md: "80px",
                  lg: "80px",
                  xl: "80px",
                },
              }}
              css={css`
                ${styles.text}
              `}
            >
              On
            </Typography>

            <Typography
              sx={{
                fontSize: {
                  xs: "35px",
                  sm: "60px",
                  md: "80px",
                  lg: "80px",
                  xl: "80px",
                },
              }}
              css={css`
                ${styles.text}
              `}
            >
              Algorand
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "15px",
                  sm: "40px",
                  md: "40px",
                  lg: "40px",
                  xl: "40px",
                },
              }}
              css={css`
                ${styles.text}
              `}
            >
              Options-Futures
            </Typography>

            <Button
              variant="contained"
              size="large"
              style={{ marginRight: 20, marginLeft: 30 }}
              sx={buttonSx}
            >
              WhitePaper
            </Button>

            <Button
              size="large"
              // onClick={}
              variant="contained"
              sx={buttonSx}
            >
              Pitch Deck
            </Button>
          </Box>
        </Box>
      </Grid>
    );
  }
}

export default Banner;
