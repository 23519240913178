import React from "react";
import PropTypes from "prop-types";
import { Typography, Tabs, Tab, Box, Button } from "@mui/material";
import ActionForm from "./ActionForm";
import algoLogo from "../assets/images/algo.png";


const buttonSx = {
  marginLeft: "8px",
  "&.MuiButton-root": {
    backgroundColor: "#0081c6",
    color: "#fff",
    paddingTop: "1px",
    paddingBottom: "1px",
  },
  "&:hover": {
    backgroundColor: "#48466d",
    color: "#fff",
  },
  margin: "4px",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class TradeActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.orderType === "long" ? 0 : 1,
    };
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.orderType !== prevProps.orderType) {
      this.setState({ value: this.props.orderType === "long" ? 0 : 1 });
    }
  }

  handleChangeTab(event, newValue) {
    this.setState({ value: newValue });
  }
  render() {
    const { value } = this.state;
    const {
      selectedClob,
      setOrderType,
      balance,
      dummyData,
      createNewOrder,
      cancelNewOrder,
      setNewOrderObj,
      newOrderObj,
      walletAddress,
      orderType
    } = this.props;
    return (
      <>
      
       <Typography
            variant="body1"
            sx={{ "&.MuiTypography-root": { fontWeight: "bold",display:'inline' } }}
          >
            Balance: {balance} 
            <Box
                component="img"
                src={algoLogo}
                sx={{
                  width: "18px",
                  display: "inline",
                  verticalAlign: "middle",
                }}
              />
          </Typography>
        <Button
          variant="contained"
          // onClick={}
          sx={buttonSx}
          size="small"
        >
          Deposit
        </Button>
        <Button
          variant="contained"
          // onClick={}
          sx={buttonSx}
          size="small"
        >
          Withdraw
        </Button>
        <Box component="div" sx={{ borderTop: "2px solid #48466d" }}>
          <Tabs
            centered
            value={value}
            onChange={this.handleChangeTab}
            aria-label="basic tabs example"
            sx={{ "& .MuiTabs-indicator": { backgroundColor: "transparent" } }}
          >
            <Tab
              label="Long"
              {...a11yProps(0)}
              sx={{
                color: "#11b119",
                fontWeight: "bold",
                "&.Mui-selected": {
                  color: "#11b119",
                  border: "1px solid #11b119",
                  borderRadius: "4px",
                  margin: "8px",
                  height: "23px",
                  minHeight: "20px",
                },
                "& .MuiTab-root": { height: "20px" },
              }}
              onClick={() => setOrderType("long")}
            />
            <Tab
              label="Short"
              {...a11yProps(1)}
              sx={{
                color: "#b11111",
                fontWeight: "bold",
                "&.Mui-selected": {
                  color: "#b11111",
                  border: "1px solid #b11111",
                  borderRadius: "4px",
                  margin: "8px",
                  height: "23px",
                  minHeight: "20px",
                },
              }}
              onClick={() => setOrderType("short")}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <ActionForm
            dummyData={dummyData}
            balance={balance}
            orderType={orderType}
            createNewOrder={createNewOrder}
            setNewOrderObj={setNewOrderObj}
            newOrderObj={newOrderObj}
            cancelNewOrder={cancelNewOrder}
            walletAddress={walletAddress}
            selectedClob={selectedClob}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ActionForm
            dummyData={dummyData}
            balance={balance}
            orderType={orderType}
            createNewOrder={createNewOrder}
            setNewOrderObj={setNewOrderObj}
            newOrderObj={newOrderObj}
            cancelNewOrder={cancelNewOrder}
            walletAddress={walletAddress}
            selectedClob={selectedClob}
          />
        </TabPanel>
      </>
    );
  }
}
TradeActions.propTypes = {
  walletAddress: PropTypes.string.isRequired,
  selectedClob: PropTypes.object.isRequired,
  newOrderObj: PropTypes.object.isRequired,
  orderType: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  setOrderType: PropTypes.func.isRequired,
  createNewOrder: PropTypes.func.isRequired,
  cancelNewOrder: PropTypes.func.isRequired,
  setNewOrderObj: PropTypes.func.isRequired,
};
export default TradeActions;
