import React from "react";
import { PropTypes } from "prop-types";
import { Tabs, Box, Typography, Tab } from "@mui/material";
import RecentTrades from "./RecentTrades";
import OpenOrders from "./OpenOrders";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

class WalletTradeHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  render() {
    const { value } = this.state;
    const { bidData,askData,walletAddress } = this.props;
    return (
      <>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(event, newValue) => this.setState({ value: newValue })}
            aria-label="basic tabs example"
          >
            
            <Tab label="Open Orders" {...a11yProps(0)} />
            <Tab label="Trade History" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <OpenOrders bidData={bidData} askData={askData} walletAddress={walletAddress}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RecentTrades />
        </TabPanel>
      </>
    );
  }
}
WalletTradeHistory.propTypes = {
  bidData: PropTypes.array.isRequired,
  askData: PropTypes.array.isRequired,
  walletAddress: PropTypes.string.isRequired,
};
export default WalletTradeHistory;
