import React from "react";
/** @jsxImportSource @emotion/react */
import HomePage from "./HomePage";
import LandingPage from "./LandingPage";
import Header from "./Header";
import Footer from "./Footer";
import { Grid } from "@mui/material";

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      landingPageView: true,
      walletConnected: false,
    };
  }
  componentDidMount(){
    console.log('MainPage component mounted')
  }

  render() {
    const { landingPageView, walletConnected } = this.state;
    return (
      <Grid container>
       
        {landingPageView ? (
           <>
           <Grid item xs={12} sm={12} md={12}>
           <Header
           walletAddress={''}
             setIsLandingPageView={(landingPageView) =>
               this.setState({ landingPageView })
             }
             setIsWalletConnected={(isConnected) =>
               this.setState({ walletConnected:isConnected})
             }
             walletConnected={walletConnected}
           />
         </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <LandingPage />
          </Grid></>
        ) : (
          <HomePage
            setIsWalletConnected={(isConnected) =>
              this.setState({ walletConnected: isConnected })
            }
          />
        )}
        {!walletConnected && (
          <Grid item xs={12} sm={12} md={12}>
            <Footer />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default MainPage;
